const links = {
    companyPage: "https://kheradazma.org/?lang=",
    wordpressLink: {
        mainPage: "https://kheradazma.org/",
        product: "https://kheradazma.org/products/",
        aboutUs: "https://kheradazma.org/about-us/",
        contactUs: "https://kheradazma.org/contact/",

    },
    navbar: {
        about: "https://www.chromatorex.com/en/about",
        servicesAndSupport: "https://www.chromatorex.com/en/support",

    },
    notfound: "/404",
    home: "/",
    product: {
        HPLC: "/products/HPLC",
        FPLC: "/products/FPLC",
        Mass_Spectrometry: "/products/Mass_Spectrometry",
        accessories: "/products/accessories",
    },
    training: {
        main: "/training",
    }


}
export default links;