import { ReactNode, Children, Component, useState, ChangeEvent, HtmlHTMLAttributes, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InterfaceSelectMassSpectrometry, interfaceFilterNumber, interfaceFilterString, interfaceProductForList, interfaceResponseServerGetFilter, interfaceResponseServerGetProducts } from "./InterfaceSelectMassSpectrometry";
import { styled as styledMUI, useTheme } from "@mui/material/styles";
import { ConfigComps } from "/Users/alirezasafafard/Documents/niktech/kheradazma/product_page_front/my-app/src/config/ConfigCOMP"
import { Link, useNavigate } from "react-router-dom";
import colors from "../../../config/colors";
import { Button, Card, Checkbox, FormControlLabel, Grid, Pagination } from "@mui/material";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import { CircleNotificationsOutlined, CircleOutlined, CircleRounded } from "@mui/icons-material";
import { ButtonFromMUI } from "../../../components/MUIConfig/ButtonFromMUI";
import ProductTableTitle from "../../../components/products/list_processes/ProductTableTitle";
import ProductTableItem from "../../../components/products/list_processes/ProductTableItem";
import { NavbarMainPage } from "../../../components/navbarMain/NavbarMainPage";
import { TextFieldFromMUI } from "../../../components/MUIConfig/TextFieldFromMUI";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { useDispatch, useSelector } from "react-redux";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { EnumSelectedSection } from "../../../components/navbarMain/InterfaceNavbarMainPage";
import SideBarProductDetails from "../../../components/AppBarMenu/sideBarProductDetails/SideBarProductDetails";
import { HelperResponsive } from "../../../helper/Helper";
const Header = styledMUI("div")(({ theme }) => ({
  background: "linear-gradient(to bottom, #3F51B5, #1E8CE4)",
  // background: "linear-gradient(to bottom, #3FB5A1, #1EE462)",
  width: "100%",
  color: colors.screens.color,
  padding: "calc(80px + 6vw) 0",
  display: "flex",
  backgroundColor: "red",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundPosition: "80% 0",
  backgroundSize: "cover",
  position: "relative"
}));
const ImageOfBackgroundHeader = styledMUI("div")(({ theme }) => ({
  display: "block",
  width: "100%",
  height: "100%",
  zIndex: "1",
  opacity: "0.3",
  background: `url(${ConfigComps().screens.product.massSpectrometry.header.backgroundImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "80% 0",
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  right: 0,
}))
const ImageOfBackgroundFooter = styledMUI("div")(({ theme }) => ({
  display: "block",
  width: "100%",
  height: "100%",
  zIndex: "1",
  opacity: "0.98",
  background: `url(${ConfigComps().screens.product.footer.backgroundImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "80% 0",
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  right: 0,
}))
const Main = styledMUI("div")(({ theme }) => ({
  marginTop: "-90px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  zIndex: "2"

}));
const ButtonSelectBondedSilica = styledMUI(ButtonFromMUI, { shouldForwardProp: (prop) => prop !== 'isSelectedBondedSlica', })<{ isSelectedBondedSlica: boolean; }>(({
  theme,
  isSelectedBondedSlica
}) => ({
  width: "100%",
  fontSize: "14px",
  borderRadius: "0",
  boxShadow: "none",
  border: `1px solid ${colors.green.dark}`,
  background: isSelectedBondedSlica ? colors.green.dark : colors.screens.color,
  color: !isSelectedBondedSlica ? colors.green.dark : colors.screens.color,
  '&:hover': {
    background: isSelectedBondedSlica ? colors.green.dark : colors.screens.color,
    color: !isSelectedBondedSlica ? colors.green.dark : colors.screens.color,
    boxShadow: "none",
  }

}));
const ButtonSelectBondedItemSilica = styledMUI(ButtonFromMUI, { shouldForwardProp: (prop) => prop !== 'isSelected', })<{ isSelected: boolean; }>(({
  theme,
  isSelected
}) => ({
  fontSize: "14px !important",
  borderRadius: "37px",
  textTransform: "none",
  padding: "4px 8px",
  width: "100%",
  height: "37px",
  boxShadow: "none",
  border: `1px solid ${colors.green.dark}`,
  background: isSelected ? colors.green.dark : colors.screens.color,
  color: !isSelected ? colors.green.dark : colors.screens.color,
  '&:hover': {
    background: isSelected ? colors.green.dark : colors.screens.color,
    color: !isSelected ? colors.green.dark : colors.screens.color,
    boxShadow: "none",
  }

}));
const ButtonFormSend = styledMUI(ButtonFromMUI)(({
  theme
}) => ({
  fontSize: "14px !important",
  borderRadius: "37px",
  color: colors.screens.product.main.tableProductSelected.form.button.color,
  background: colors.green.dark2,
  textTransform: "none",
  height: "37px",
  boxShadow: "none",
  padding: "1em 5em",
  '&:hover': {
    background: colors.screens.product.main.tableProductSelected.form.button.hoverBackground,

    boxShadow: "none",
  }

}));

const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

  position: "absolute",
  top: "0",
  left: "0",
  pointerEvents: "none",

  width: "120%",
  height: "101%",
  zIndex: "-1",
  transformOrigin: "0% 100%",
  transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
  transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
}));
const DivCardSubmit = styledMUI("div")(({ theme }) => ({
  width: "calc(100% - 44px)", display: "flex", flexDirection: "column", border: "1px solid #d2d2d2",
  boxSizing: "border-box",
  alignSelf: "end",
  [theme.breakpoints.down('md')]: {
    alignSelf: "center",
    width: "100%"
  },

  padding: "38px 26px 23px",
  marginBottom: "30px"
}))
const ButtonProducts = styledMUI(Button)(({ theme }) => ({
  color: colors.screens.color,
  pointerEvents: "auto",
  borderRadius: "2em",
  padding: "0.5rem 3rem",
  marginBottom: "18px",
  textAlign: "center",
  border: "1px solid",
  fontSize: "14px",
  overflow: "hidden",
  position: "relative",
  zIndex: "2",
  '&:hover': {
    color: colors.green.dark2,
    'div': {
      position: "absolute",
      top: "0",
      left: "0",
      width: "120%",
      height: "100%",
      background: "#fff",
      zIndex: "-1",
      transform: "rotate(0deg) translate3d(0, 0em, 0)",
    }
  }
}));
const ButtonDownloadBrochure = styledMUI(Button)(({ theme }) => ({
  color: colors.screens.color,
  pointerEvents: "auto",
  borderRadius: "2em",
  padding: "0.5rem 3rem",
  margin: "0",
  textAlign: "center",
  border: "1px solid",
  fontSize: "14px",
  overflow: "hidden",
  position: "relative",
  zIndex: "2",
  background: "#CE93D8",
  '&:hover': {
    color: colors.green.dark2,
    'div': {
      position: "absolute",
      top: "0",
      left: "0",
      width: "120%",
      height: "100%",
      background: "#fff",
      zIndex: "-1",
      transform: "rotate(0deg) translate3d(0, 0em, 0)",
    }
  }
}));
export const SelectMassSpectrometry = (paramsPage: InterfaceSelectMassSpectrometry) => {
  const navigate = useNavigate();
  const mTheme = useTheme();
  const { t, i18n } = useTranslation();
  var confComps = ConfigComps();
  const [getBondedCardView, setBondedCardView] = useState<boolean>(false);
  const [getFormName, setFormName] = useState<string>("");
  const [getFormCompany, setFormCompany] = useState<string>("");
  const [getFormEmail, setFormEmail] = useState<string>("");
  const [getFormCountry, setFormCountry] = useState<string>("");
  const [getFormMessage, setFormMessage] = useState<string>("");
  const [getFilterLength, setFilterLength] = useState<interfaceFilterNumber[]>([]);
  const [getFilterInnerLength, setFilterInnerLength] = useState<interfaceFilterNumber[]>([]);
  const [getFilterParticuleSize, setFilterParticuleSize] = useState<interfaceFilterNumber[]>([]);
  const [getFilterPoreSize, setFilterPoreSize] = useState<interfaceFilterNumber[]>([]);
  const [getFilterBondedSilica, setFilterBondedSilica] = useState<interfaceFilterString[]>([]);
  const [getProductsList, setProductsList] = useState<interfaceProductForList[]>([]);
  const [getPageSelected, setPageSelected] = useState<number>(0);
  const [getSidebarProductPropertise, setSidebarProductPropertise] = useState<interfaceProductForList & { lastTimeUpdate: number }>();
  const dispatch = useDispatch();
  const profileInStore = useSelector(SelectedProfile);
  const filterCondition = (x: interfaceProductForList) => {
    return (
      (
        (getFilterInnerLength.filter(inner => (inner.isSelected == true)).length == 0) ||
        (getFilterInnerLength.filter(inner => (inner.isSelected == true) && (x.inner_diameter == inner.value)).length > 0)
      ) &&
      (
        (getFilterParticuleSize.filter(inner => (inner.isSelected == true)).length == 0) ||
        (getFilterParticuleSize.filter(inner => (inner.isSelected == true) && (x.particle_size == inner.value)).length > 0)
      ) &&
      (
        (getFilterLength.filter(inner => (inner.isSelected == true)).length == 0) ||
        (getFilterLength.filter(inner => (inner.isSelected == true) && (x.length == inner.value)).length > 0)
      ) &&
      (
        (getFilterPoreSize.filter(inner => (inner.isSelected == true)).length == 0) ||
        (getFilterPoreSize.filter(inner => (inner.isSelected == true) && (x.pore_size == inner.value)).length > 0)
      )
      &&
      (
        (getBondedCardView == false && x.bonding == undefined) ||
        (getBondedCardView == true && (x.bonding != undefined) && ((getFilterBondedSilica.filter(inner => (inner.isSelected == true) && (x.bonding == inner.value)).length > 0) || (getFilterBondedSilica.filter(inner => inner.isSelected == true).length == 0)))

      )
    )
  }
  const onclickBareSilicaHandler = () => {
    setBondedCardView(false)
  }
  const onclickBondedSilicaHandler = () => {
    setBondedCardView(true)
  }
  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageSelected(value);
  };
  const handleOnClickSubmitForm = async () => {
    dispatch(isVisibilityProgressBar(true));
    const objectForPost = {
      name: getFormName,
      email: getFormEmail,
      company: getFormCompany,
      country: getFormCountry,
      message: getFormMessage,
      products: getProductsList.filter(product => product.isSelected == true).map((value, index, array) => {
        return (value.id)
      }),
    };
    await RequestHandler(dispatch, HandlerLangUrl(urlsList.product.postOrderProduct.url, mTheme),
      urlsList.product.postOrderProduct.method,
      urlsList.product.postOrderProduct.isTokenNecessary, objectForPost,
      profileInStore)
      .then((response: any) => {
        console.log("response:):)");
        console.log(response);
        dispatch(isVisibilityProgressBar(false));
        dispatch(ShowAlert({ text: "we will contact you.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
      })
      .catch((e: Error) => {
        console.log("response:(");

        console.log(e);
        dispatch(isVisibilityProgressBar(false));
        if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
          dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        }
        else {
          dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        }
      });
  }
  const handleOnclickSelected = (indexServer: number) => {
    let oldProductList = [...getProductsList]; // copying the old products array
    let index = oldProductList.findIndex(x => x.id == indexServer)
    oldProductList[index].isSelected = !oldProductList[index].isSelected
    setProductsList(oldProductList)
    if (getSidebarProductPropertise?.id ?? -1.5 == indexServer) {
      setSidebarProductPropertise({
        ...getProductsList.filter(x => x.id == indexServer)[0]
        , ["lastTimeUpdate"]: Date.now()
      })
    }
  }
  const handleOnclickActiveSidebarForProductDetails = (indexServer: number) => {
    console.log("handleOnclickActiveSidebarForProductDetails")
    console.log(indexServer)
    if (getProductsList.filter(x => x.id == indexServer).length > 0) {
      setSidebarProductPropertise(
        {
          ...getProductsList.filter(x => x.id == indexServer)[0]
          , ["lastTimeUpdate"]: Date.now()
        })
    }
    else {
      setSidebarProductPropertise(undefined)
    }
  }
  const requestForGetFilters = async () => {
    dispatch(isVisibilityProgressBar(true));

    await RequestHandler(dispatch, HandlerLangUrl(urlsList.product.getFilterList.url, mTheme),
      urlsList.product.getFilterList.method,
      urlsList.product.getFilterList.isTokenNecessary, undefined, profileInStore)
      .then((response: any) => {
        var temp = response as interfaceResponseServerGetFilter
        setFilterLength(temp.length.map((value, index, array) => {
          return ({
            "value": value,
            "isSelected": false,
          })
        }))
        setFilterBondedSilica(temp.bonding.map((value, index, array) => {
          return ({
            "value": value,
            "isSelected": false,
          })
        }))
        setFilterPoreSize(temp.pore.map((value, index, array) => {
          return ({
            "value": value,
            "isSelected": false,
          })
        }))
        setFilterInnerLength(temp.inner_diameter.map((value, index, array) => {
          return ({
            "value": value,
            "isSelected": false,
          })
        }))
        setFilterParticuleSize(temp.particle.map((value, index, array) => {
          return ({
            "value": value,
            "isSelected": false,
          })
        }))
        dispatch(isVisibilityProgressBar(false));
        dispatch(ShowAlert({ text: "Fetch data ok.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
      })
      .catch((e: Error) => {
        console.log("response:(");

        console.log(e);
        dispatch(isVisibilityProgressBar(false));
        if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
          dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        }
        else {
          dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        }
      })
  }
  useEffect(() => {
    console.log("getFilterParticuleSize")
    console.log(getFilterParticuleSize)
  }, getFilterParticuleSize)
  const requestForGetProducts = async () => {
    dispatch(isVisibilityProgressBar(true));

    await RequestHandler(dispatch, HandlerLangUrl(urlsList.product.getProductList.url, mTheme),
      urlsList.product.getProductList.method,
      urlsList.product.getProductList.isTokenNecessary, {
      "pore_size": [],
      "particle_size": []
    },
      profileInStore)
      .then((response: any) => {
        console.log("response:):)");
        console.log(response);
        let res = response as interfaceResponseServerGetProducts;
        console.log(res);
        setProductsList(
          res.product.map((value, index, array) => {
            console.log(value)
            return (
              {
                "isSelected": false,
                "id": value.id,
                "product_name": value.product_name,
                "pdf_catalog": value.pdf_catalog,
                "product_description": value.product_description,
                "pore_diameter": value.pore_diameter,
                "surface_area": value.surface_area,
                "sio2": value.sio2,
                "capping_treatment": value.capping_treatment,
                "utilization": value.utilization,
                "applications": value.applications,
                "pore_size_label": value.pore_size_label,
                "particle_size_label": value.particle_size_label,
                "length_label": value.length_label,
                "inner_diameter_label": value.inner_diameter_label,
                "bonding": value.bonding,
                "pore_size": value.pore_size,
                "particle_size": value.particle_size,
                "length": value.length,
                "inner_diameter": value.inner_diameter,
                "bonding_label": value.bonding_label
              }
            )
          }
          )
        )

        dispatch(isVisibilityProgressBar(false));
        dispatch(ShowAlert({ text: "Fetch data ok.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
      })
      .catch((e: Error) => {
        console.log("response:(");

        console.log(e);
        dispatch(isVisibilityProgressBar(false));
        if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
          dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        }
        else {
          dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        }
      });
  }
  useEffect(() => {
    requestForGetProducts().then(requestForGetFilters)

  }, []);
  return (
    <div style={{
      width: "100%",
      height: "auto",
      overflow: "auto",
      display: "flex",
      // paddingTop: "90px",
      flexDirection: "column",
      justifyContent: "start",
      alignContent: "start",
      alignItems: "start",
      textAlign: "center",
      background: "#FFF"
    }}>
      <SideBarProductDetails
        isHamburgerMenuShow={!(getSidebarProductPropertise == undefined)}
        typeOfDisplay={HelperResponsive()}
        callBackOnClose={() => {
          setSidebarProductPropertise(undefined);
        }}
        isHamburgerMenuStaticAndFixPosition={false}
        productPropertis={getSidebarProductPropertise}
        callbackSelectProduct={handleOnclickSelected}
      />
      <Header>
        <ImageOfBackgroundHeader />
        <h1>{confComps.screens.product.massSpectrometry.header.title}</h1>
        {/* <p style={{ fontStyle: "italic", maxWidth: "500px", fontSize: "19px" }}>
          {confComps.screens.product.header.subtitle1}
          <br />
          {confComps.screens.product.header.subtitle2}
        </p> */}
        <br />
        <br />
      </Header>
      <Main>
        <Card style={{ maxWidth: "940px", width: "100%" }}>
          <div style={{ padding: "10px 10px 30px" }}>
            <GridFromMUI container
              justifyContent="space-between" alignItems="flex-start"
              rowSpacing={{ xs: 1, sm: 2, md: 8, lg: 9, xl: 10 }}
              columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

              <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                <div style={{ paddingInlineEnd: "4px", display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <p style={{ whiteSpace: "nowrap", paddingInlineStart: "10px" }}>{confComps.screens.product.massSpectrometry.main.cardSelectSize.section1.title}</p>
                  <div style={{ width: "100%" }}>
                    <GridFromMUI container
                      alignItems="start"
                      rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                      {getFilterParticuleSize.map((value, index, array) => {
                        return (
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", }}>
                              <FormControlLabel
                                value={value.value}
                                sx={{
                                  '& .MuiFormControlLabel-label': { fontSize: '0.8em', fontWeight: "Light" }
                                }}
                                control={
                                  <Checkbox
                                    checked={value.isSelected}
                                    onClick={() => {
                                      console.log("safa")
                                      let oldVersion = [...getFilterParticuleSize]
                                      oldVersion[getFilterParticuleSize.findIndex(x => x.value == value.value)].isSelected = !oldVersion[getFilterParticuleSize.findIndex(x => x.value == value.value)].isSelected
                                      console.log(oldVersion)
                                      setFilterParticuleSize(oldVersion)
                                    }}
                                    icon={<CircleOutlined />}
                                    sx={{
                                      color: colors.green.dark,
                                      '&.Mui-checked': {
                                        color: colors.green.logo,
                                      }
                                    }}
                                    checkedIcon={<CircleRounded />}
                                  />
                                }
                                label={`${value.value}`}
                                style={{ color: colors.green.dark, fontSize: "10", margin: "0" }}
                                labelPlacement="bottom"
                              />
                            </div>
                          </Grid>
                        )
                      })
                      }
                    </GridFromMUI>
                  </div>
                </div>
              </Grid>
              {/* length mm */}
              <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                <div style={{ paddingInlineEnd: "4px", display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <p style={{ whiteSpace: "nowrap", paddingInlineStart: "10px" }}>
                    {confComps.screens.product.massSpectrometry.main.cardSelectSize.section2.title}
                  </p>
                  <div style={{ width: "100%" }}>
                    <GridFromMUI container
                      justifyContent="space-between"
                      alignItems="center"
                      rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                      {getFilterPoreSize.map((value, index, array) => {
                        return (
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", }}>
                              <FormControlLabel
                                value={value.value}
                                sx={{
                                  '& .MuiFormControlLabel-label': { fontSize: '0.8em', fontWeight: "Light" }
                                }}
                                control={
                                  <Checkbox
                                    checked={value.isSelected}
                                    onClick={() => {
                                      console.log("safa")
                                      let oldVersion = [...getFilterPoreSize]
                                      oldVersion[getFilterPoreSize.findIndex(x => x.value == value.value)].isSelected = !oldVersion[getFilterPoreSize.findIndex(x => x.value == value.value)].isSelected
                                      console.log(oldVersion)
                                      setFilterPoreSize(oldVersion)
                                    }}
                                    icon={<CircleOutlined />}
                                    sx={{
                                      color: colors.green.dark,
                                      '&.Mui-checked': {
                                        color: colors.green.logo,
                                      }
                                    }}
                                    checkedIcon={<CircleRounded />}
                                  />
                                }
                                label={`${value.value}`}
                                style={{ color: colors.green.dark, fontSize: "10", margin: "0" }}
                                labelPlacement="bottom"
                              />
                            </div>
                          </Grid>
                        )
                      })
                      }
                    </GridFromMUI>
                  </div>
                </div>
              </Grid>
              {/* inner diameter */}
              <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                <div style={{ paddingInlineEnd: "4px", display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <p style={{ whiteSpace: "nowrap", paddingInlineStart: "10px" }}>
                    {confComps.screens.product.massSpectrometry.main.cardSelectSize.section3.title}</p>
                  <div style={{ width: "100%" }}>
                    <GridFromMUI container
                      alignItems="start"
                      rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                      {getFilterLength.map((value, index, array) => {
                        return (
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", }}>
                              <FormControlLabel
                                value={value.value}
                                sx={{
                                  '& .MuiFormControlLabel-label': { fontSize: '0.8em', fontWeight: "Light" }
                                }}
                                control={
                                  <Checkbox
                                    checked={value.isSelected}
                                    onClick={() => {
                                      console.log("safa")
                                      let oldVersion = [...getFilterLength]
                                      oldVersion[getFilterLength.findIndex(x => x.value == value.value)].isSelected = !oldVersion[getFilterLength.findIndex(x => x.value == value.value)].isSelected
                                      console.log(oldVersion)
                                      setFilterLength(oldVersion)
                                    }}
                                    icon={<CircleOutlined />}
                                    sx={{
                                      color: colors.green.dark,
                                      '&.Mui-checked': {
                                        color: colors.green.logo,
                                      }
                                    }}
                                    checkedIcon={<CircleRounded />}
                                  />
                                }
                                label={`${value.value}`}
                                style={{ color: colors.green.dark, fontSize: "10", margin: "0" }}
                                labelPlacement="bottom"
                              />
                            </div>
                          </Grid>
                        )
                      })
                      }
                    </GridFromMUI>
                  </div>
                </div>
              </Grid>
              {/* part2 of card */}
              <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                <div style={{ paddingInlineEnd: "4px", display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <p style={{ whiteSpace: "nowrap", paddingInlineStart: "10px" }}>
                    {confComps.screens.product.massSpectrometry.main.cardSelectSize.section4.title}</p>
                  <div style={{ width: "100%" }}>
                    <GridFromMUI container
                      justifyContent="space-between" alignItems="flex-start"
                      rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                      {getFilterInnerLength.map((value, index, array) => {
                        return (
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", }}>
                              <FormControlLabel
                                value={value.value}
                                sx={{
                                  '& .MuiFormControlLabel-label': { fontSize: '0.8em', fontWeight: "Light" }
                                }}
                                control={
                                  <Checkbox
                                    checked={value.isSelected}
                                    onClick={() => {
                                      console.log("safa")
                                      let oldVersion = [...getFilterInnerLength]
                                      oldVersion[getFilterInnerLength.findIndex(x => x.value == value.value)].isSelected = !oldVersion[getFilterInnerLength.findIndex(x => x.value == value.value)].isSelected
                                      console.log(oldVersion)
                                      setFilterInnerLength(oldVersion)
                                    }}
                                    icon={<CircleOutlined />}
                                    sx={{
                                      color: colors.green.dark,
                                      '&.Mui-checked': {
                                        color: colors.green.logo,
                                      }
                                    }}
                                    checkedIcon={<CircleRounded />}
                                  />
                                }
                                label={`${value.value}`}
                                style={{ color: colors.green.dark, fontSize: "10", margin: "0" }}
                                labelPlacement="bottom"
                              />
                            </div>
                          </Grid>
                        )
                      })
                      }
                    </GridFromMUI>
                  </div>
                </div>
              </Grid>

              <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <ButtonSelectBondedSilica isSelectedBondedSlica={!getBondedCardView} onClick={onclickBareSilicaHandler}>
                    {confComps.screens.product.massSpectrometry.main.cardSelectSize.tabs.tab1.title}
                  </ButtonSelectBondedSilica>
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <ButtonSelectBondedSilica isSelectedBondedSlica={getBondedCardView} onClick={onclickBondedSilicaHandler}>
                    {confComps.screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.title}
                  </ButtonSelectBondedSilica>
                </div>
              </Grid>
            </GridFromMUI>
            <div style={{ width: "100%", display: getBondedCardView ? "block" : "none" }}>
              <div style={{ border: "1px solid #d2d2d2", paddingTop: "20px", paddingBottom: "20px" }}>
                <div style={{ margin: "0 10px" }}>
                  <GridFromMUI container
                    alignItems="start"
                    rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
                    columns={{ xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }}
                  >
                    {getFilterBondedSilica.map((value, index, array) => {
                      return (
                        <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                          <ButtonSelectBondedItemSilica isSelected={value.isSelected}
                            onClick={() => {

                              console.log("safa")
                              let oldVersion = [...getFilterBondedSilica]
                              oldVersion[getFilterBondedSilica.findIndex(x => x.value == value.value)].isSelected = !oldVersion[getFilterBondedSilica.findIndex(x => x.value == value.value)].isSelected
                              console.log(oldVersion)
                              setFilterBondedSilica(oldVersion)

                            }}>
                            {value.value}
                          </ButtonSelectBondedItemSilica>
                        </Grid>
                      )
                    })}
                  </GridFromMUI>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", paddingTop: "30px" }}>
              <p style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
                {confComps.screens.product.massSpectrometry.main.cardSelectSize.productAvailabe}
                <p style={{ color: colors.green.dark }}>{getProductsList.filter(filterCondition).length}</p>
                <p>{`/`}</p>
                <p>{getProductsList.length}</p>
              </p>
            </div>
          </div>
        </Card>
        <br />
        <br />
        <br />
        <div style={{ width: "100%", maxWidth: "940px" }}>
          <div style={{ width: "100%", overflow: "scroll" }}>
            <table style={{ borderCollapse: "collapse", borderSpacing: "0", minWidth: "100%" }}>
              <ProductTableTitle titlesObj={confComps.screens.product.massSpectrometry.main.table.header} />
              <tbody>
                {
                  getProductsList.filter(filterCondition).map((value, index, array) => {
                    return (
                      <ProductTableItem isSelected={value.isSelected}
                        product_description={value.product_description} particle_size={value.particle_size}
                        pore_size={value.pore_size}
                        bonding={value.bonding}

                        id={value.id}
                        callbackSelected={handleOnclickSelected}
                        callbackSideBarClicked={handleOnclickActiveSidebarForProductDetails}
                        product_name={value.product_name}
                        pdf_catalog={value.pdf_catalog}
                        pore_diameter={value.pore_diameter}
                        surface_area={value.surface_area}
                        sio2={value.sio2}
                        capping_treatment={value.capping_treatment}
                        utilization={value.utilization}
                        applications={value.applications}
                        pore_size_label={value.pore_size_label}
                        particle_size_label={value.particle_size_label}
                        length_label={value.length_label}
                        inner_diameter_label={value.inner_diameter_label}
                        length={value.length}
                        inner_diameter={value.inner_diameter}
                        bonding_label={value.bonding_label} />
                    )
                  })
                }
              </tbody>

            </table>
          </div>
          <div style={{ width: "100%", paddingTop: "1vh", paddingBottom: "1vh" }}>
            <div style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center" }}>
              <Pagination count={1 + getProductsList.length / 32} variant="outlined" shape="rounded" page={getPageSelected} onChange={handleChangePagination} />

            </div>
          </div>
          <div style={{
            maskBorderMode: "inherit", flexDirection: "column",
            justifyContent: "space-between",
            // alignItems:"center",
            display: getProductsList.filter(product => product.isSelected == true).length == 0 ? "none" : "flex"
          }}>
            <h3 style={{ alignSelf: "center" }}>{confComps.screens.product.massSpectrometry.main.tableProductSelected.title}</h3>
            <p style={{ alignSelf: "center", color: colors.screens.border, textAlign: "center" }}>
              {`${getProductsList.filter(product => product.isSelected == true).length} ${confComps.screens.product.massSpectrometry.main.tableProductSelected.subtitle}`}
            </p>
            <div style={{ width: "100%", overflow: "scroll" }}>
              <table style={{ borderCollapse: "collapse", borderSpacing: "0", width: "100%" }}>
                <ProductTableTitle titlesObj={confComps.screens.product.massSpectrometry.main.table.header} />
                <tbody>
                  {
                    getProductsList.filter(product => product.isSelected == true).map((value, index, array) => {
                      return (
                        <ProductTableItem
                          isSelected={value.isSelected}

                          pore_size={value.pore_size} bonding={value.bonding}

                          id={value.id}
                          callbackSelected={handleOnclickSelected}
                          callbackSideBarClicked={handleOnclickActiveSidebarForProductDetails} product_name={value.product_name} pdf_catalog={value.pdf_catalog}
                          product_description={value.product_description} pore_diameter={value.pore_diameter}
                          surface_area={value.surface_area}
                          sio2={value.sio2}
                          capping_treatment={value.capping_treatment} utilization={value.utilization} applications={value.applications} particle_size={value.particle_size}
                          pore_size_label={value.pore_size_label}
                          particle_size_label={value.particle_size_label}
                          length_label={value.length_label}
                          inner_diameter_label={value.inner_diameter_label}
                          length={value.length}
                          inner_diameter={value.inner_diameter} bonding_label={value.bonding_label} />
                      )
                    })
                  }
                </tbody>

              </table>
            </div>
            <DivCardSubmit>
              <h6 style={{
                color: colors.screens.border,
                marginBottom: "12px"
              }}>{confComps.screens.product.massSpectrometry.main.tableProductSelected.form.title}
              </h6>
              <p style={{ textAlign: "center", margin: "13px 96px 34px" }}>
                {confComps.screens.product.massSpectrometry.main.tableProductSelected.form.subtitle}
              </p>
              <GridFromMUI container
                alignItems="start"
                rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
                columns={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 4 }}
              >
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.name.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormName(event.target.value) }}
                    multiline={false}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.email.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormEmail(event.target.value) }}
                    multiline={false}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.company.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormCompany(event.target.value) }}
                    multiline={false}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.country.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormCountry(event.target.value) }}
                    multiline={false}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
                <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.message.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormMessage(event.target.value) }}
                    multiline={true}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
              </GridFromMUI>
              <div style={{ marginTop: "15px" }}>
                <ButtonFormSend onClick={handleOnClickSubmitForm}>
                  {confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.button.text}
                </ButtonFormSend>
              </div>
            </DivCardSubmit>
          </div>
        </div>
        <div style={{
          width: "100%", position: "relative", padding: "80px 0",
          display: "flex", flexDirection: "column", alignItems: "center",
          justifyContent: "center",
          background: colors.screens.product.main.footer.background,
        }}>
          <h2 style={{ color: colors.screens.color, marginBottom: "20px" }}>
            {confComps.screens.product.footer.title}
          </h2>
          <ButtonProducts>
            <ShadowButtonProducts />
            {confComps.screens.product.footer.buttonService.text}
          </ButtonProducts>
          <ButtonDownloadBrochure>
            <ShadowButtonProducts />
            {confComps.screens.product.footer.buttonBrochure.text}
          </ButtonDownloadBrochure>

          <ImageOfBackgroundFooter />
        </div>
      </Main >
    </div >
  );
};

export default SelectMassSpectrometry;

