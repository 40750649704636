import { EnumSelectedSection, InterfaceNavbarMainPage, enumLanguage } from "./InterfaceNavbarMainPage";
import { styled as styledMUI, useTheme } from "@mui/material/styles";
import { Button, FormControl, InputLabel, Menu, MenuItem, Select, getAccordionActionsUtilityClass, useScrollTrigger } from "@mui/material";
import { ArrowCircleDownRounded, ArrowDropDownRounded, ArrowDropUpRounded, DragHandleSharp, DragIndicatorRounded, EmailRounded, HomeMaxRounded, HomeRounded, PersonOutlineOutlined, PhoneRounded, ProductionQuantityLimitsRounded, ScienceRounded, ShoppingCartOutlined } from "@mui/icons-material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as React from "react";
import colors from "../../config/colors";

import links from "../../config/links";
import Badge, { BadgeProps } from '@mui/material/Badge';
import { HelperResponsive } from "../../helper/Helper";

import HamburgerMenuOverWriteOnAnyPage from "../AppBarMenu/hamburgerMenu/HamburgerMenuOverWriteOnAnyPage";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import { ConfigComps } from "../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { SetDirectionOfTheme } from "../../store/themeModeSlice";
import { useTranslation } from "react-i18next";
import { SelectFromMUI } from "../MUIConfig/SelectFromMUI";
import { MenuItemFromMUI } from "../MUIConfig/MenuItemFromMUI";
import SideBarProductDetails from "../AppBarMenu/sideBarProductDetails/SideBarProductDetails";
import { isatty } from "tty";

// const NAVBAR_PADDING_HEIGHT = 3;
// const NAVBAR_PADDING_HEIGHT_HALF_UNIT_MOBILE = NAVBAR_PADDING_HEIGHT / 4 + "rem";
// const NAVBAR_PADDING_HEIGHT_QUARTER_UNIT = NAVBAR_PADDING_HEIGHT / 9 + "rem";
// const NAVBAR_DISTANCE_FROM_TOP = 2;
// const NAVBAR_DISTANCE_FROM_TOP_UNIT = NAVBAR_DISTANCE_FROM_TOP + "vw";
// const ITEM_FONT_SIZE = 24;
// const ITEM_FONT_SIZE_UNIT = ITEM_FONT_SIZE + "px";
// const ITEM_FONT_SIZE_UNIT_MOBILE = ITEM_FONT_SIZE / 2 + "px";


const Container = styledMUI("div")(({ theme }) => ({
    position: "fixed",
    width: "100%",
    top: 0,
    height: "calc(30px + 2.5vw)",
    boxShadow: "0 1px 0 rgba(0,0,0,0.08)",
    transition: "top 1s",
    transitionTimingFunction: "ease-out",
    zIndex: 10,
}));
const Nav = styledMUI("div")(({ theme }) => ({
    background: colors.Navbar.backgroundColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "-webkit-fill-available",
    height: "100%",
    borderBottom: `1px solid ${colors.Navbar.bottomBorder}`,
    padding: "0px 24px",

}));
const RefNameCompany = styledMUI("h2")(({ theme }) => ({
    marginRight: "100px",
    transition: "0.3s all",
    lineHeight: "50px",
    color: "#fff",
}));
const NavContent = styledMUI("div")(({ theme }) => ({
    width: "100%",
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        padding: "0.15rem",
        width: "100%",
    },
}));
const LogoContainer = styledMUI("div")(({ theme }) => ({
    // fontSize: ITEM_FONT_SIZE_UNIT,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        // fontSize: ITEM_FONT_SIZE_UNIT_MOBILE,
    }
}));
const ItemListContainer = styledMUI("ul")(({ theme }) => ({
    listStyle: "none",
    width: "",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        display: "none",
    }
}));

const ButtonProducts = styledMUI(IconButton, { shouldForwardProp: (prop) => prop !== 'isActive' })<{ isActive: boolean; }>(({
    theme,
    isActive
}) => {
    return (
        {
            fontSize: "14px !important",
            borderRadius: "37px",
            border: "1px solid",
            textTransform: "none",
            height: "37px",
            boxShadow: isActive ? "none" : colors.shadow.boxShadowDarkInBottom,
            padding: "1em 2em",
            background: isActive ? colors.green.dark : undefined,
            color: isActive ? "white" : colors.green.dark2,
        }
    )
});


const ItemContainer = styledMUI('p', { shouldForwardProp: (prop) => prop !== "color" && prop !== 'selected', })<{ selected?: boolean; color?: string }>(({
    theme,
    selected
}) => ({
    [theme.breakpoints.up('md')]: {
        // fontFamily: `${selected ? 'vazirmatn-ExtraBold' : 'vazirmatn-medium'} !important`,
        // fontSize: `${selected ? '1.56rem' : '1.37rem'} !important`,
    },
    [theme.breakpoints.down('md')]: {
        // fontFamily: `${selected ? 'vazirmatn-ExtraBold' : 'vazirmatn-Regular'} !important`,
        // fontSize: `${selected ? '1.37rem' : '1rem'} !important`,
    },
    margin: "0",
    fontSize: "14px",
    fontWeight: "600",
    textDecoration: "blink",
    border: "none",
    cursor: "pointer",
    color: colors.Navbar.color
}));
const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

    background: "red",
    position: "absolute",
    top: "0",
    left: "0",
    pointerEvents: "none",

    width: "120%",
    height: "101%",
    zIndex: "-1",
    transformOrigin: "0% 100%",
    transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
    transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
}));
const ButtonProductsShadow = styledMUI(Button)(({ theme }) => ({
    color: colors.Navbar.color,
    pointerEvents: "auto",
    borderRadius: "2em",
    padding: "0.5rem 3rem",
    margin: "0",
    textAlign: "center",
    border: "1px solid",
    fontSize: "14px",
    overflow: "hidden",
    position: "relative",
    zIndex: "2",
    '&:hover': {
        color: colors.Navbar.backgroundColor,
        'div': {
            position: "absolute",
            top: "0",
            left: "0",
            width: "120%",
            height: "100%",
            background: "#fff",
            zIndex: "-1",
            transform: "rotate(0deg) translate3d(0, 0em, 0)",
        }
    }
}));
const StyledBadge = styledMUI(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 24,
        background: colors.green.dark2,

        // border: `2px solid ${theme.palette.background.paper}`,
        padding: '2px 4px 0px 4px',
    },
}));
const SelectMenuLanguage = styledMUI(SelectFromMUI)(({ theme }) => ({
    "div.MuiSelect-select": {
        fontSize: "0.92rem !important",
        fontWeight: "600",
    },
    "fieldset": {
        border: "0"
    },
    "svg": {
        color: colors.Navbar.color
    }
}));
const HamburgerMenuDiv = styledMUI(IconButton)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "none",
    }
}));
const HamburgerMenuBtn = styledMUI(IconButton)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "none",
    }
}));



// const ImgLogoVisibility = styled("img")`
//   visibility: visible;
//   ${mobile({display: "none"})}
// `;

export const NavbarMainPage = (componentParams: InterfaceNavbarMainPage) => {
    const navigate = useNavigate();
    const mTheme = useTheme();
    const { t, i18n } = useTranslation();
    var confComps = ConfigComps();

    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 10 });

    // initialize dispatch variable
    const dispatch = useDispatch();
    const [getLanguage, setLanguage] = useState<enumLanguage>(mTheme.direction == "ltr" ? enumLanguage.en : enumLanguage.fa);
    const isLocationContainLink = (url: string) => {
        if (window.location.href.toLowerCase().includes(url.toLowerCase())) {
            return true;
        }
        return false;
    };
    const [isShowHamburgerMenu, setIsShowHamburgerMenu] = useState<boolean>(false);

    const onClickInHamburgerMenu = () => {
        setIsShowHamburgerMenu(true);
    }
    const onCloseHamburgerMenu = () => {
        setIsShowHamburgerMenu(false);
    }
    const handleChangeLanguge = (event: any) => {
        console.log(event);
        setLanguage(event.target.value)
        i18n.changeLanguage(mTheme.direction == "rtl" ? "en" : "fa");
        dispatch(SetDirectionOfTheme(mTheme.direction == "rtl" ? "ltr" : "rtl"));
    };
    const handleChangeProductCategory = (event: any) => {
        console.log(event);
        if (event.target.value != "HPLC_Column")
            window.location.replace(`https://kheradazma.org/products/?lang=${mTheme.direction == "rtl" ? "fa" : "en"}`)
        // setLanguage(event.target.value)
        // i18n.changeLanguage(mTheme.direction == "rtl" ? "en" : "fa");
        // dispatch(SetDirectionOfTheme(mTheme.direction == "rtl" ? "ltr" : "rtl"));
    };
    const [getAnchorElButtonProducts, setAnchorElButtonProducts] = React.useState<null | HTMLElement>(null);
    const [getHoverMouseActive, setHoverMouseActive] = useState<boolean>(true)
    const isOpenButtonProducts = Boolean(getAnchorElButtonProducts);
    // const handleClickButtonProducts = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     if (getAnchorElButtonProducts == null) {
    //         // setAnchorElButtonProducts(event.currentTarget);
    //     }
    //     setHoverMouseActive(false)

    // };
    const handleCloseButtonProducts = (goto: EnumSelectedSection) => {
        if (goto != componentParams.SelectUrlSelected) {
            if (goto === EnumSelectedSection.Accessories) {
                navigate(links.product.accessories);
            }
            else if (goto === EnumSelectedSection.FPLC) {
                navigate(links.product.FPLC);
            }
            else if (goto === EnumSelectedSection.HPLC) {
                navigate(links.product.HPLC);
            }
            else if (goto === EnumSelectedSection.MassSpectrometry) {
                navigate(links.product.Mass_Spectrometry);
            }
        }
        // window.location.replace(`https://kheradazma.org/products/?lang=${mTheme.direction == "rtl" ? "fa" : "en"}`)

    };
    return (
        <Container>
            <Nav>
                <NavContent>
                    <HamburgerMenuOverWriteOnAnyPage
                        isHamburgerMenuShow={isShowHamburgerMenu}
                        typeOfDisplay={HelperResponsive()}
                        callBackOnClose={onCloseHamburgerMenu}
                        isHamburgerMenuStaticAndFixPosition={false}
                        listLinksInTop={[...confComps.navbarMainPage.linksTitles,
                        {
                            "icon": ScienceRounded,
                            "link": links.wordpressLink.product,
                            "name": confComps.navbarMainPage.product,
                        }]} />

                    <HamburgerMenuDiv
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onClickInHamburgerMenu}
                        style={{ padding: "0px" }}
                    >
                        <MenuIcon fontSize={"large"} />
                    </HamburgerMenuDiv>


                    <Link to={`${links.companyPage}${mTheme.direction == "rtl" ? "fa" : "en"}`} style={{ textDecoration: "none", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                        <img src={confComps.navbarMainPage.logo} style={{ height: "100%" }}></img>
                    </Link>
                    <ItemListContainer>
                        {componentParams.listTitle.map(((value, index) => {
                            return (
                                <li style={{ marginInlineStart: "30px", display: "inline-block" }}>
                                    <Link to={value.link + ((mTheme.direction == "ltr") ? `/?lang=en` : "")} key={index} style={{ textDecoration: "blink", textAlign: "center" }}>
                                        <ItemContainer selected={isLocationContainLink(value.link)}>
                                            {value.name}
                                        </ItemContainer>
                                    </Link>
                                </li>
                            );
                        }))}
                        <li style={{ marginInlineStart: "30px", display: "inline-block" }}>
                            <ButtonProducts
                                id="basic-button"
                                aria-controls={isOpenButtonProducts ? 'basic-menu' : undefined}
                                aria-expanded={isOpenButtonProducts ? 'true' : undefined}
                                onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    if (getHoverMouseActive && getAnchorElButtonProducts == null) {

                                        setAnchorElButtonProducts(event.currentTarget);
                                    }
                                }}
                                onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    if (getHoverMouseActive) {
                                    }
                                }}
                                isActive={Boolean(getAnchorElButtonProducts)}
                            >
                                {confComps.navbarMainPage.product}
                                {isOpenButtonProducts ? <ArrowDropUpRounded /> :
                                    <ArrowDropDownRounded />}
                            </ButtonProducts>
                            <Menu
                                id="basic-menu"
                                anchorEl={getAnchorElButtonProducts}
                                open={isOpenButtonProducts}
                                onClose={() => {
                                    setHoverMouseActive(true)
                                    setAnchorElButtonProducts(null)
                                }}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}

                            >
                                <MenuItem onClick={() => {
                                    handleCloseButtonProducts(EnumSelectedSection.HPLC)
                                }}
                                    selected={componentParams.SelectUrlSelected == EnumSelectedSection.HPLC}
                                    style={
                                        componentParams.SelectUrlSelected == EnumSelectedSection.HPLC ?
                                            {
                                                color: "white",
                                                background: colors.green.dark2
                                            }
                                            : {}
                                    }

                                >
                                    HPLC Columns
                                </MenuItem>
                                <MenuItem
                                    selected={componentParams.SelectUrlSelected == EnumSelectedSection.FPLC}
                                    onClick={() => { handleCloseButtonProducts(EnumSelectedSection.FPLC) }}
                                    style={
                                        componentParams.SelectUrlSelected == EnumSelectedSection.FPLC ?
                                            {
                                                color: "white",
                                                background: colors.green.dark2
                                            }
                                            : {}
                                    }
                                >
                                    FPLC Columns
                                </MenuItem>
                                <MenuItem
                                    onClick={() => { handleCloseButtonProducts(EnumSelectedSection.MassSpectrometry) }}
                                    selected={componentParams.SelectUrlSelected == EnumSelectedSection.MassSpectrometry}
                                    style={
                                        componentParams.SelectUrlSelected == EnumSelectedSection.MassSpectrometry ?
                                            {
                                                color: "white",
                                                background: colors.green.dark2
                                            }
                                            : {}
                                    }
                                >
                                    Mass Spectrometry
                                </MenuItem>
                                <MenuItem onClick={() => { handleCloseButtonProducts(EnumSelectedSection.Accessories) }}
                                    selected={componentParams.SelectUrlSelected == EnumSelectedSection.Accessories}
                                    style={
                                        componentParams.SelectUrlSelected == EnumSelectedSection.Accessories ?
                                            {
                                                color: "white",
                                                background: colors.green.dark2
                                            }
                                            : {}
                                    }
                                >
                                    Accessories
                                </MenuItem>
                            </Menu>

                        </li>
                    </ItemListContainer>
                    <ItemListContainer style={{ height: "100%" }}>



                        <li style={{
                            display: "inline-block",
                            borderWidth: "0 0 0 1px", borderStyle: "solid",
                            borderColor: "rgba(120, 120, 120, 0.50)",
                            paddingInlineEnd: "30px",
                            marginInlineEnd: "30px",
                        }}>
                            <a href="mailto:info@kheradazma.com"
                                style={{
                                    fontSize: "0.9rem",
                                    textDecoration: "none",
                                    color: "#424242",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <LogoContainer style={{ marginInlineEnd: "0.4rem" }}>
                                    <EmailRounded style={{ fontSize: "1.1rem" }} />
                                </LogoContainer>

                                {confComps.footerMainPage.contactUs.email}
                            </a>
                        </li>
                        <li style={{ display: "inline-block" }}>
                            <a href="tel:+982122850456"
                                style={{
                                    fontSize: "0.9rem",
                                    textDecoration: "none",
                                    color: "#424242",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <LogoContainer style={{ marginInlineEnd: "0.4rem" }}>
                                    <PhoneRounded style={{ fontSize: "1.1rem" }} />
                                </LogoContainer>
                                {confComps.footerMainPage.contactUs.phone}
                            </a>
                        </li>
                    </ItemListContainer>

                </NavContent>
            </Nav>
        </Container >
    );

}