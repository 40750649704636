import * as React from 'react';
import { styled as styledMUI, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import colors from "../../../config/colors";
import { Drawer, IconButton, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import {
    AccountBalanceWalletRounded, AccountBalanceWalletSharp,
    ArrowDropDownRounded,
    ArrowDropUpRounded,
    ForumRounded,
    ForumSharp,
    HomeRounded,
    PowerSettingsNewRounded
} from "@mui/icons-material";
import interfaceHamburgerMenuOverWriteOnAnyPage from "./interfaceHamburgerMenuOverWriteOnAnyPage";
import { CSSProperties } from "@mui/material/styles/createTypography";
import links from "../../../config/links";
import { Link } from "react-router-dom";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import { useEffect, useState } from "react";
import { HelperResponsive } from "../../../helper/Helper";
import { useTranslation } from 'react-i18next';
import { ConfigComps } from '../../../config/ConfigCOMP';
import { useDispatch, useSelector } from "react-redux";
import { SetDirectionOfTheme } from '../../../store/themeModeSlice';

const styleOfText: CSSProperties = {
    textAlign: "start",
    textDecoration: "none"
}
const styleOfIcon: CSSProperties = {
    justifyContent: "center"
}
const CustomDrawer = styledMUI(Drawer)(({ theme }) => ({
    "& .MuiPaper-elevation": {
        marginTop: "0px",
        borderEndEndRadius: "10px",

        [theme.breakpoints.up('md')]: {
            zIndex: 999,
            boxShadow: theme.direction == "rtl" ?
                "-2px 2px 1px -1px rgb(0 0 0 / 20%), -1px 1px 1px 0px rgb(0 0 0 / 14%), -1px 1px 3px 0px rgb(0 0 0 / 12%)" :
                " 1px 2px 1px -1px rgb(0 0 0 / 20%),  1px 1px 1px 0px rgb(0 0 0 / 14%),  1px 1px 3px 0px rgb(0 0 0 / 12%)",
            marginTop: "69px",
            height: "-webkit-fill-available",
        }
    },
    "& .MuiPaper-root": {
        left: theme.direction == "rtl" ? "unset" : "0",
        right: theme.direction == "rtl" ? "0" : "unset",
    }


}));
const CustomListItemButton = styled(ListItemButton)((theme) => ({
    borderRadius: "5px",
    textAlign: "right",
    "&.Mui-selected, &.Mui-selected:hover": {
        // color: colors.hamburgerMenu.textSelected,
        // backgroundColor: colors.hamburgerMenu.ButtonSelected
    }


}));

const ButtonProducts = styledMUI(IconButton)(({
    theme
}) => ({
    fontSize: "14px !important",
    borderRadius: "37px",
    color: colors.green.dark2,
    border: "1px solid",
    // background: colors.green.dark2,
    textTransform: "none",
    height: "37px",
    boxShadow: "none",
    padding: "1em 2em",
    '&:hover': {
        background: colors.green.dark,
        color: "white",
        boxShadow: "none",
    }

}));
function HamburgerMenuOverWriteOnAnyPage(paramsComponent: interfaceHamburgerMenuOverWriteOnAnyPage) {
    const mTheme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    var confComps = ConfigComps();
    useEffect(() => {

    }, []);
    const [getAnchorElButtonProducts, setAnchorElButtonProducts] = React.useState<null | HTMLElement>(null);
    const [getHoverMouseActive, setHoverMouseActive] = useState<boolean>(true)
    const isOpenButtonProducts = Boolean(getAnchorElButtonProducts);

    const handleClickButtonProducts = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (getAnchorElButtonProducts == null) {
            setAnchorElButtonProducts(event.currentTarget);
        }
        setHoverMouseActive(false)

    };
    const handleSetPersion = () => {
        if (mTheme.direction != "rtl") {
            // setLanguage("fa")
            i18n.changeLanguage("fa");
            dispatch(SetDirectionOfTheme("rtl"));
        }

    };
    const handleSetEnglish = () => {
        if (mTheme.direction == "rtl") {
            // setLanguage("en")
            i18n.changeLanguage("en");
            dispatch(SetDirectionOfTheme("ltr"));
        }
    };

    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (paramsComponent.callBackOnClose) {
            paramsComponent.callBackOnClose();
        }
    }


    const isLocationContainLink = (url: string) => {
        if (window.location.href.toLowerCase().includes(url.toLowerCase())) {
            return true;
        }
        return false;
    };
    return (
        <CustomDrawer

            variant={(paramsComponent.isHamburgerMenuStaticAndFixPosition) ? "permanent" : "temporary"}
            //from which side the drawer slides in
            anchor={mTheme.direction == "rtl" ? "left" : "left"}
            //if open is true --> drawer is shown
            open={paramsComponent.isHamburgerMenuShow}
            //function that is called when the drawer should close
            onClose={toggleDrawer(false)}
            style={{
                paddingTop: (paramsComponent.isHamburgerMenuStaticAndFixPosition) ? "40px" : undefined,
                direction: mTheme.direction
            }}
            dir={mTheme.direction}
        //function that is called when the drawer should open
        // onOpen={toggleDrawer(true)}
        >
            <div style={{
                padding: "1rem",
                height: "100%",
                boxSizing: "border-box",
                width: paramsComponent.typeOfDisplay > enumListSizeDevices.tablet ? "20vw" : undefined,
                minWidth: paramsComponent.typeOfDisplay > enumListSizeDevices.tablet ? undefined : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "space-between",
            }}>

                <Box sx={{ mb: 2 }}>
                    {paramsComponent.listLinksInTop.map((value, index, array) => {
                        return (
                            <Link to={value.link} key={index} style={{
                                textDecoration: "none",
                                color: "black",
                                //     colors.hamburgerMenu.textSelected : colors.hamburgerMenu.iconColor
                            }}>
                                <CustomListItemButton
                                    selected={isLocationContainLink(value.link)}>
                                    <ListItemIcon style={styleOfIcon}>
                                        {<value.icon
                                            fontSize={"large"}
                                            style={{ color: colors.green.dark }}
                                        />}
                                    </ListItemIcon>
                                    <ListItemText primary={value.name} style={styleOfText} />


                                </CustomListItemButton>
                            </Link>
                        )
                    })}
                    <div style={{ marginInlineStart: "30px", display: "inline-block" }}>
                        <ButtonProducts
                            id="basic-button"
                            aria-controls={isOpenButtonProducts ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isOpenButtonProducts ? 'true' : undefined}
                            onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) => {
                                if (getHoverMouseActive && getAnchorElButtonProducts == null) {

                                    setAnchorElButtonProducts(event.currentTarget)
                                }
                            }}
                            onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => {
                                if (getHoverMouseActive) {
                                    // setAnchorElButtonProducts(null)
                                }
                            }}
                            onClick={handleClickButtonProducts}
                        >
                            {mTheme.direction == "rtl" ? "فارسی" : "english"}
                            {isOpenButtonProducts ? <ArrowDropUpRounded /> :
                                <ArrowDropDownRounded />}
                        </ButtonProducts>
                        <Menu
                            id="basic-menu"
                            anchorEl={getAnchorElButtonProducts}
                            open={isOpenButtonProducts}
                            onClose={() => {
                                setHoverMouseActive(true)
                                setAnchorElButtonProducts(null)
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleSetEnglish}
                                style={{
                                    color: mTheme.direction == "rtl" ? "black" : "white",
                                    background: mTheme.direction == "rtl" ? "white" : colors.green.dark2
                                }}
                            >ENGLISH</MenuItem>
                            <MenuItem onClick={handleSetPersion}
                                style={{
                                    color: mTheme.direction != "rtl" ? "black" : "white",
                                    background: mTheme.direction != "rtl" ? "white" : colors.green.dark2
                                }}
                            >فارسی</MenuItem>
                        </Menu>


                    </div>

                </Box>


                <div style={{ width: "100%", alignSelf: "end", justifySelf: "end", boxSizing: "border-box", padding: "8px calc(3vw + 16px)" }}>
                    <Link to={`${links.companyPage}${mTheme.direction == "rtl" ? "fa" : "en"}`} style={{ textDecoration: "none", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                        <img src={`url(${confComps.navbarMainPage.logo})`} style={{ width: "100%" }}></img>
                    </Link>
                </div>
            </div>

        </CustomDrawer>
    );
}


export default HamburgerMenuOverWriteOnAnyPage;