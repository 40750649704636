import { enumListSizeDevices } from "../../config/responsive/configResponsive";
import { interfaceProductForList } from "../../screens/product/InterfaceSelectFeatures";
import { InterfacePropertisSideBarProductDetails } from "../AppBarMenu/sideBarProductDetails/InterfaceSideBarProductDetails";

export interface InterfaceNavbarMainPage {
    listTitle: InterfaceOneTitleOfNavbar[];
    typeOfDisplay: enumListSizeDevices;
    SelectUrlSelected: EnumSelectedSection;
}
export enum enumLanguage {
    en = "en",
    fa = "fa"
};
export enum EnumSelectedSection {
    HPLC = "hplc",
    FPLC = "fplc",
    MassSpectrometry = "massSpectrometry",
    Accessories = "accessories",
}
export interface InterfaceOneTitleOfNavbar {
    name: string;
    link: string;
}